<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="655px"  
  >
    <v-card>
      <v-card-title>
      </v-card-title>    
      <v-card-text>
        <v-row class="mt-4">
          <v-col>
            <p class="text-center black--text">BOTONES DE PAGO</p> 
            <p class="text-center mt-n5 black--text">PARA TARJETA DE CRÉDITO, DÉBITO, TRANSFERENCIA Y BILLETERA VIRTUAL</p>
          </v-col>
        </v-row>
        <v-row justify="center" class="ml-16 mt-6">
            <v-col class="d-flex justify-center">
              <v-img :src="mediosPago[6].img" @click="setMedioPago(mediosPago[6].medio)" max-width="180" style="cursor:pointer"></v-img>
            </v-col>
            <v-col>
              <v-img :src="mediosPago[1].img" @click="setMedioPago(mediosPago[1].medio)" max-width="180" style="cursor:pointer"></v-img>
            </v-col>            
        </v-row>
        <v-row style="margin-top: 80px;">
          <v-col>
            <p class="text-center black--text" >OTROS MEDIOS DE PAGO</p> 
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-6 ml-4">
          <v-col class="d-flex justify-center">
              <v-img :src="mediosPago[2].img" @click="setMedioPago(mediosPago[2].medio)" max-width="170" style="cursor:pointer"></v-img>
            </v-col>
            <v-col>
              <v-img :src="mediosPago[0].img" @click="setMedioPago(mediosPago[0].medio)" max-width="180" style="cursor:pointer"></v-img>
            </v-col>           
            <v-col>
              <v-img :src="mediosPago[3].img" @click="setMedioPago(mediosPago[3].medio)" max-width="170" style="cursor:pointer"></v-img>
            </v-col>                   
        </v-row>  
      </v-card-text>
      <v-card-actions class="justify-center" style="margin-top: 80px;">
        <botonVolver @backPage="show = false;$store.commit('setbackground', 'background2')" :prevent="true" class="mr-2 ml-2" style="margin-bottom: 25px;" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>           

<script>
  export default {    
    props: {
        value: Boolean,
    },
    data () { return {
        mediosPago: [
          { img: '/img/logo-pagomiscuentas-nuevo.png' , medio: 'pmc'},
          { img: '/img/logo-mercadopago-nuevo.png' , medio: 'mercado_pago'},
          { img: '/img/logo-linkpagos-nuevo.png' , medio: 'link_pagos'},
          { img: '/img/logo-interbanking-nuevo.png' , medio: 'interbanking'},
          { img: '/img/tarjetas.png' , medio: 'tarjeta_credito'},
          { img: '/img/transferencias.png' , medio: 'transferencia'},
          { img: '/img/logo-epagos-nuevo.png' , medio: 'pago_electronico'},          
        ]    
      }
    },
    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },    
    methods: {
      setMedioPago: function (medioPago) {        
        this.$emit('setQr', medioPago)     
        this.show = false
      },
    },
  }
</script>

<style scoped>
.v-application .text-center {
    text-align: center !important;
    font-weight: 600;
}
</style>